import { BASE_API_URL } from '../../utils/constants';
import { ConsultantResponse, ImportantLinksResponse } from '../interfaces';

export class ConsultantService {
    static async getById (id: number | string): Promise<ConsultantResponse> {
        const response = await fetch(`${BASE_API_URL}/consultants?id=${id}`);
        return response.json();
    }

    static async getImportantLinks(id: number | string): Promise<ImportantLinksResponse> {
        const response = await fetch(`${BASE_API_URL}/users-mailings/signature?id=${id}`);
        return response.json();
    }
}
