import { Phrases } from '../phrases';

export const frenchLocale = {
  [Phrases.orderConsultation]: 'Commander une consultation',
  [Phrases.orderingConsultation]: 'Commande de consultation',
  [Phrases.youAreGoingToOrderConsultation]: 'Vous allez commander une consultation',
  [Phrases.phoneNumber]: 'Numéro de téléphone',
  [Phrases.orderConsultationButton]: 'Commander une consultation',
  [Phrases.consultant]: 'Consultant',
  [Phrases.leaveCommentLabel]: 'Laisser un commentaire',
  [Phrases.send]: 'Envoyer',
  [Phrases.thankYouForComment]: 'Merci pour votre commentaire',
  [Phrases.consultantWillReceiveMessageSoon]: 'Le consultant recevra bientôt votre message',
  [Phrases.previousLesson]: 'Leçon précédente',
  [Phrases.nextLesson]: 'Leçon suivante',
  [Phrases.personalConsultant]: 'Consultant personnel',
  [Phrases.consultantWillContactYouForFree]: 'Le consultant vous contactera gratuitement',
  [Phrases.contactTelegram]: 'Contacter via Telegram',
  [Phrases.or]: 'ou',
  [Phrases.lessonDescription]: 'Description de la leçon',
  [Phrases.takeYourBonus]: 'Obtenez votre bonus',
  [Phrases.takeBonus]: 'Obtenir le bonus',
  [Phrases.rateUsefulnessOfMaterial]: "Évaluer l'utilité du matériel",
  [Phrases.copyright]: 'Droits d\'auteur',
  [Phrases.channelLink]: 'Lien vers le canal',
  [Phrases.registrationToCompanyLink]: 'Lien d\'inscription à la société',
  [Phrases.systemActivationDate]: 'Date d\'activation du système',
  [Phrases.importantLinks]: 'Liens importants',
  [Phrases.telegramChannel]: 'Chaîne Telegram',
  [Phrases.registrationWith]: 'Inscription avec',
  [Phrases.systemActivation]: 'Activation du système',
  [Phrases.timing]: 'Horaire',
  [Phrases.follow]: 'Suivre'
}
