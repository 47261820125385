import { BASE_API_URL } from '../../utils/constants';
import {
    LessonResponse, UserResponse, UserLessonResponse,
    NavigationResponse,
    RateRequest
} from '../interfaces';

interface NavLinkResponse {
    link: string;
}

export class LessonService {
    static async getById (lessonId: number | string): Promise<LessonResponse> {
        const response = await fetch(`${BASE_API_URL}/lessons?id=${lessonId}`);
        return response.json();
    }

    static async getUserLessonById (userId: number | string, lessonId: number | string): Promise<UserLessonResponse> {
        const response = await fetch(`${BASE_API_URL}/users-lessons?userId=${userId}&lessonId=${lessonId}`);
        return response.json();
    }

    static async getUserById (userId: number | string): Promise<UserResponse> {
        const response = await fetch(`${BASE_API_URL}/users?id=${userId}`);
        return response.json();
    }

    static async getLessonNavigation(currentLessonId: string | number): Promise<NavigationResponse> {
        const response = await fetch(`${BASE_API_URL}/users-lessons/allowed-navigation?currentLessonId=${currentLessonId}`);
        return response.json();
    }

    static async getPreviousLesson(userId: string | number, lessonId: string | number): Promise<NavLinkResponse> {
        const response = await fetch(`${BASE_API_URL}/users-lessons/previous?userId=${userId}&lessonId=${lessonId}`);
        return response.json();    
    }

    static async getNextLesson(userId: string | number, lessonId: string | number): Promise<NavLinkResponse> {
        const response = await fetch(`${BASE_API_URL}/users-lessons/next?userId=${userId}&lessonId=${lessonId}`);
        return response.json();    
    }

    static async setLessonRating (rate: RateRequest): Promise<string> {
        const response = await fetch(`${BASE_API_URL}/users-lessons/rate`, {
            method: 'PATCH',
            body: JSON.stringify(rate),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }
}
