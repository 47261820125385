import { BASE_API_URL } from '../../utils/constants';

export class PlayerService {
    static async setReadingDate (userId: number, lessonId: number): Promise<void> {
        await fetch(`${BASE_API_URL}/users-lessons/set-reading-date`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId, lessonId })
        });
    }
}
