import { Phrases } from '../phrases';

export const japaneseLocale = {
  [Phrases.orderConsultation]: '相談を注文する',
  [Phrases.orderingConsultation]: '相談を注文中',
  [Phrases.youAreGoingToOrderConsultation]: '相談を注文しますか',
  [Phrases.phoneNumber]: '電話番号',
  [Phrases.orderConsultationButton]: '相談を注文する',
  [Phrases.consultant]: 'コンサルタント',
  [Phrases.leaveCommentLabel]: 'コメントを残す',
  [Phrases.send]: '送信',
  [Phrases.thankYouForComment]: 'コメントありがとうございます',
  [Phrases.consultantWillReceiveMessageSoon]: 'コンサルタントがすぐにメッセージを受け取ります',
  [Phrases.previousLesson]: '前のレッスン',
  [Phrases.nextLesson]: '次のレッスン',
  [Phrases.personalConsultant]: '個人コンサルタント',
  [Phrases.consultantWillContactYouForFree]: 'コンサルタントが無料で連絡します',
  [Phrases.contactTelegram]: 'Telegramで連絡する',
  [Phrases.or]: 'または',
  [Phrases.lessonDescription]: 'レッスンの説明',
  [Phrases.takeYourBonus]: 'ボーナスを受け取る',
  [Phrases.takeBonus]: 'ボーナスを受け取る',
  [Phrases.rateUsefulnessOfMaterial]: '教材の有用性を評価する',
  [Phrases.copyright]: '著作権',
  [Phrases.channelLink]: 'チャンネルリンク',
  [Phrases.registrationToCompanyLink]: '会社への登録リンク',
  [Phrases.systemActivationDate]: 'システムの有効化日',
  [Phrases.importantLinks]: '重要なリンク',
  [Phrases.telegramChannel]: 'Telegramチャンネル',
  [Phrases.registrationWith]: '登録',
  [Phrases.systemActivation]: 'システムの有効化',
  [Phrases.timing]: 'タイミング',
  [Phrases.follow]: 'フォローする'
}
