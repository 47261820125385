import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { englishLocale } from './locales/english';
import { russianLocale } from './locales/russian';
import { ukrainianLocale } from './locales/ukrainian';
import { portugueseLocale } from './locales/portuguese';
import { spanishLocale } from './locales/spanish';
import { vietnameseLocale } from './locales/vietnamese';
import { frenchLocale } from './locales/french';
import { japaneseLocale } from './locales/japanese';
import { koreanLocale } from './locales/korean';
import { turkishLocale } from './locales/turkish';
import { arabicLocale } from './locales/arabic';
import { indonesianLocale } from './locales/indonesian';
import { germanLocale } from './locales/german';
import { italianLocale } from './locales/italian';
import { thaiLocale } from './locales/thai';
import { filipinoLocale } from './locales/filipino';
import { chineseLocale } from './locales/chinese';


export function i18nSetup(): void {
  i18n
    .use(initReactI18next)
    .init({
      resources: {
        en: { translation: englishLocale },
        ru: { translation: russianLocale },
        uk: { translation: ukrainianLocale },
        pt: { translation: portugueseLocale },
        es: { translation: spanishLocale },
        vi: { translation: vietnameseLocale },
        fr: { translation: frenchLocale },
        ja: { translation: japaneseLocale},
        ko: { translation: koreanLocale },
        tr: { translation: turkishLocale },
        ar: { translation: arabicLocale },
        id: { translation: indonesianLocale },
        de: { translation: germanLocale },
        it: { translation: italianLocale },
        th: { translation: thaiLocale },
        ph: { translation: filipinoLocale },
        ch: { translation: chineseLocale },
      },
      fallbackLng: 'en',
      lng: navigator.language
    });
}
