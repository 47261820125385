import { useState } from 'react';
import { Card, Row } from 'antd';
import Rating from 'react-rating';
import { StarEmpty, StarFilled } from '../../../assets/images/icons'
import { LessonService } from '../../services';
import './Rank.css';
import {Phrases} from '../../../translations/phrases';
import {useTranslation} from 'react-i18next';

interface Props {
	rating: number;
	userId: number;
	lessonId: number;
}

export const Rank = ({ rating, userId, lessonId }: Props): JSX.Element => {
	const [rate, setRate] = useState<number>(0);
	const {t} = useTranslation();
	const rateSetterObj = {
    userId,
    lessonId,
	}

	const handleRankChange = (value: number) => {
		setRate(value);
		LessonService.setLessonRating({...rateSetterObj, rate: value})
	}

	return (
		<Card>
			{ rate === 0 ? <p className="lesson-rate_text">{t(Phrases.rateUsefulnessOfMaterial)}</p> : null }
			<Row>
				{ rate !== 0 ? <p className="lesson-rating">{rate}</p> : null }
				<Rating
					start={0}
					stop={5}
					initialRating={rate}
					emptySymbol={<StarEmpty />}
					fullSymbol={<StarFilled />}
					onClick={handleRankChange}
				/>
			</Row>
		</Card>
	)
};
