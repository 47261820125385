import { Phrases } from '../phrases';

export const thaiLocale = {
  [Phrases.orderConsultation]: 'สั่งการปรึกษา',
  [Phrases.orderingConsultation]: 'กำลังสั่งการปรึกษา',
  [Phrases.youAreGoingToOrderConsultation]: 'คุณกำลังจะสั่งการปรึกษา',
  [Phrases.phoneNumber]: 'หมายเลขโทรศัพท์: ',
  [Phrases.orderConsultationButton]: 'สั่งการปรึกษา',
  [Phrases.consultant]: 'ที่ปรึกษา',
  [Phrases.leaveCommentLabel]: 'แสดงความคิดเห็น',
  [Phrases.send]: 'ส่ง',
  [Phrases.thankYouForComment]: 'ขอบคุณสำหรับความคิดเห็น',
  [Phrases.consultantWillReceiveMessageSoon]: 'ที่ปรึกษาจะได้รับข้อความเร็ว ๆ นี้',
  [Phrases.previousLesson]: 'บทเรียนก่อนหน้า',
  [Phrases.nextLesson]: 'บทเรียนถัดไป',
  [Phrases.personalConsultant]: 'ที่ปรึกษาส่วนตัว',
  [Phrases.consultantWillContactYouForFree]: 'ที่ปรึกษาจะติดต่อคุณฟรี',
  [Phrases.contactTelegram]: 'ติดต่อทาง Telegram',
  [Phrases.or]: 'หรือ',
  [Phrases.lessonDescription]: 'คำอธิบายบทเรียน',
  [Phrases.takeYourBonus]: 'รับโบนัสของคุณ',
  [Phrases.takeBonus]: 'รับโบนัส',
  [Phrases.rateUsefulnessOfMaterial]: 'ให้คะแนนความเป็นประโยชน์ของเนื้อหา',
  [Phrases.copyright]: 'ลิขสิทธิ์',
  [Phrases.channelLink]: 'ลิงก์ช่อง',
  [Phrases.registrationToCompanyLink]: 'ลิงก์การลงทะเบียนกับบริษัท',
  [Phrases.systemActivationDate]: 'วันที่เปิดใช้งานระบบ',
  [Phrases.importantLinks]: 'ลิงก์ที่สำคัญ',
  [Phrases.telegramChannel]: 'ช่อง Telegram',
  [Phrases.registrationWith]: 'การลงทะเบียนด้วย',
  [Phrases.systemActivation]: 'การเปิดใช้งานระบบ',
  [Phrases.timing]: 'การจับเวลา',
  [Phrases.follow]: 'ติดตาม'
}
