import { Card } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTranslation } from 'react-i18next';
import { ChapterDTO } from '../../interfaces/lesson-response.interface';
import { Phrases } from '../../../translations/phrases';
import './Timing.css';

interface Props {
	chapters: Array<ChapterDTO>;
}

dayjs.extend(duration)

export const Timing = ({ chapters }: Props): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Card className="timing-section">
			<h2 className="section-title">{t(Phrases.timing)}</h2>
			{chapters.map(({ secondsPast, description }) => {
				const formattedTime = dayjs.duration(secondsPast, 'seconds').format('HH:mm:ss');
	
				return (
					<p key={secondsPast}>
						<span className="seconds-past">{formattedTime}</span> — {description}
					</p>
				)
				})
			}
		</Card>
	)
}
