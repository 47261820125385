import { Phrases } from '../phrases';

export const koreanLocale = {
  [Phrases.orderConsultation]: '상담 주문',
  [Phrases.orderingConsultation]: '상담 주문 중',
  [Phrases.youAreGoingToOrderConsultation]: '상담을 주문하려고 합니다',
  [Phrases.phoneNumber]: '전화번호',
  [Phrases.orderConsultationButton]: '상담 주문하기',
  [Phrases.consultant]: '상담사',
  [Phrases.leaveCommentLabel]: '의견 남기기',
  [Phrases.send]: '보내기',
  [Phrases.thankYouForComment]: '의견 주셔서 감사합니다',
  [Phrases.consultantWillReceiveMessageSoon]: '상담사가 곧 메시지를 받게 될 것입니다',
  [Phrases.previousLesson]: '이전 레슨',
  [Phrases.nextLesson]: '다음 레슨',
  [Phrases.personalConsultant]: '개인 상담사',
  [Phrases.consultantWillContactYouForFree]: '상담사가 무료로 연락드릴 것입니다',
  [Phrases.contactTelegram]: '텔레그램으로 연락하기',
  [Phrases.or]: '또는',
  [Phrases.lessonDescription]: '레슨 설명',
  [Phrases.takeYourBonus]: '보너스 받기',
  [Phrases.takeBonus]: '보너스 받기',
  [Phrases.rateUsefulnessOfMaterial]: '자료의 유용성 평가하기',
  [Phrases.copyright]: '저작권',
  [Phrases.channelLink]: '채널 링크',
  [Phrases.registrationToCompanyLink]: '회사 등록 링크',
  [Phrases.systemActivationDate]: '시스템 활성화 날짜',
  [Phrases.importantLinks]: '중요 링크',
  [Phrases.telegramChannel]: '텔레그램 채널',
  [Phrases.registrationWith]: '회사 등록',
  [Phrases.systemActivation]: '시스템 활성화',
  [Phrases.timing]: '타이밍',
  [Phrases.follow]: '따르다'
}
