import { Phrases } from '../phrases';

export const russianLocale = {
  [Phrases.orderConsultation]: 'Заказать консультацию',
  [Phrases.orderingConsultation]: 'Заказ консультации',
  [Phrases.youAreGoingToOrderConsultation]: 'Вы собираетесь заказать консультацию у',
  [Phrases.phoneNumber]: 'Номер телефона',
  [Phrases.orderConsultationButton]: 'Заказать консультацию!',
  [Phrases.consultant]: 'Консультант',
  [Phrases.leaveCommentLabel]: 'Помогите нам стать лучше, оставьте Ваш комментарий: что Вам понравилось, что не понравилось, что на Ваш взгляд стоит улучшить',
  [Phrases.send]: 'Отправить',
  [Phrases.thankYouForComment]: 'Спасибо за Ваш комментарий',
  [Phrases.consultantWillReceiveMessageSoon]: 'Спасибо! Консультант получит уведомление и свяжется с Вами при первой возможности.',
  [Phrases.previousLesson]: 'Предыдущий',
  [Phrases.nextLesson]: 'Следующий',
  [Phrases.personalConsultant]: 'Ваш личный консультант',
  [Phrases.consultantWillContactYouForFree]: 'Консультант бесплатно свяжется с вами',
  [Phrases.contactTelegram]: 'Связаться в телеграм',
  [Phrases.or]: 'или',
  [Phrases.lessonDescription]: 'Описание видео',
  [Phrases.takeYourBonus]: 'Заберите ваш бонус',
  [Phrases.takeBonus]: 'Забрать бонус',
  [Phrases.rateUsefulnessOfMaterial]: 'Оцените полезность материала',
  [Phrases.copyright]: 'Все права защищены',
  [Phrases.channelLink]: 'Ссылка на канал',
  [Phrases.registrationToCompanyLink]: 'Ссылка на регистрацию в компанию',
  [Phrases.systemActivationDate]: 'Дата активации системы',
  [Phrases.importantLinks]: 'Важные ссылки',
  [Phrases.telegramChannel]: 'Telegram канал',
  [Phrases.registrationWith]: 'Регистрация в',
  [Phrases.systemActivation]: 'Активация системы',
  [Phrases.timing]: 'Тайминг',
  [Phrases.follow]: 'перейти',
}
