import { useState, useEffect } from 'react';
import { Layout, Row, Col, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { LessonDescription, LessonHeading, Copyright, Consultant, Player, Timing, Rank, GiftBanner } from './components';
import { LessonData, LessonResponse, UserResponse, UserLessonResponse, NavigationResponse } from './interfaces';
import { LessonService } from './services';
import { Phrases } from '../translations/phrases';
import { DisplayFeedbackControlsEnum } from './enums/display-feeback-controls.enum';

import './Lesson.css';


export const Lesson = () => {
	const { Content, Header, Footer } = Layout;
	const [lesson, setLesson] = useState<LessonResponse>();
	const displayFeedbackControls: DisplayFeedbackControlsEnum | null = lesson ? (lesson as any).course.buttonsAvailable : null;
	const [user, setUser] = useState<UserResponse>();
	const [language, setLanguage] = useState<string>();
	const [userLesson, setUserLesson] = useState<UserLessonResponse>();
	const [navigation, setNavigation] = useState<NavigationResponse>();
	const { t, i18n } = useTranslation();
	const isLessonDescriptionEmpty = (body: string) => body === '<p></p>\n';
	const data: LessonData = JSON.parse(atob(window.location.search.slice(4)));
	const { userId, lessonId } = data;


	useEffect(() => {
		if (language) i18n.changeLanguage(language);
	}, [language, i18n])

	const handlePrevNavLinkClick = (e: React.MouseEvent<HTMLElement>): void => {
		e.preventDefault();
		LessonService.getPreviousLesson(userId, lessonId)
			.then((res) => window.location.href = res.link);
	}

	const handleNextNavLinkClick = (e: React.MouseEvent<HTMLElement>): void => {
		e.preventDefault();
		LessonService.getNextLesson(userId, lessonId)
			.then((res) => window.location.href = res.link);
	}

	useEffect(() => {
		LessonService
			.getById(lessonId)
			.then((lesson) => {
				setLesson(lesson);
				setLanguage((lesson as any).course.mailing.language);
			});
		LessonService
			.getLessonNavigation(lessonId)
			.then(setNavigation);
		LessonService
			.getUserById(userId)
			.then(responseUser => {
				setUser(responseUser);
				LessonService
					.getUserLessonById(userId, lessonId)
					.then(responseUserLesson => {
						setUserLesson(responseUserLesson);
					});
			});

	}, [ setLesson, setUser, setNavigation, userId, lessonId ]);

	return (
		<Layout>
			{user && lesson &&
				<Content className="content">
					<Header className="lesson-header">
						<Image src={process.env.REACT_APP_LOGO_URL} preview={false} />
					</Header>
					<div className="lesson-container">
						<Row justify="space-between">
							<Col>
								{navigation?.previous && (
									<a className="nav-link nav-link_prev" href='/' onClick={handlePrevNavLinkClick}>
										<span className="nav-link__arrow">←</span> {t(Phrases.previousLesson)}
									</a>
								)}
							</Col>
							<Col>
								<p className="lesson-subtitle">{lesson.subtitle}</p>
							</Col>
							<Col>
								{navigation?.next && (
									<a
										className="nav-link nav-link_next"
										href='/'
										onClick={handleNextNavLinkClick}
									>
										{t(Phrases.nextLesson)} <span className="nav-link__arrow">→</span>
									</a>
								)}
							</Col>
						</Row>
						<LessonHeading title={lesson.title} />
						<div className="lesson-content">
							{lesson.videoUrl &&
								<Player
									className="player"
									url={lesson.videoUrl}
									userId={user.id}
									lessonId={lesson.id}
								/>
							}
							<div className="border-top-orange border-bottom-orange">
								<Row>
									<Col
										xl={{ span: 12, order: 1 }}
										lg={{ span: 12, order: 1 }}
										md={{ span: 12, order: 1 }}
										sm={{ span: 24, order: 2 }}
										xs={{ span: 24, order: 2 }}
									>
										{lesson.body && !isLessonDescriptionEmpty(lesson.body) && <LessonDescription html={lesson.body} />}
										<Rank userId={user.id} lessonId={lesson.id} rating={userLesson?.rate ?? 0} />
									</Col>
									<Col
										xl={{ span: 12, order: 2 }}
										lg={{ span: 12, order: 2 }}
										md={{ span: 12, order: 2 }}
										sm={{ span: 24, order: 1 }}
										xs={{ span: 24, order: 1 }}
									>
										{user.mailings && userLesson &&
											<Consultant
												user={user}
												consultantId={user.mailings.find(item => item.id === userLesson?.userMailingId)?.consultantId ?? 0}
												userMailingId={user.mailings.find(item => item.id === userLesson?.userMailingId)?.id ?? 0}
												displayFeedbackControls={displayFeedbackControls}
												showSignature={lesson.showSignature}
											/>
										}
									</Col>
								</Row>
								{lesson.chapters && lesson.chapters.length > 0 && (
									<Row className="border-top-orange">
										<Col span={24} style={{ width: '100%' }}>
											<Timing chapters={lesson.chapters} />
										</Col>
									</Row>
								)}
							</div>
						</div>
						{lesson.giftDownloadUrl &&
							<GiftBanner name={lesson.giftName} link={lesson.giftDownloadUrl} />
						}
					</div>
					<Footer>
						<Copyright />
					</Footer>
				</Content>
			}
		</Layout>
	);
}
