import { useEffect } from 'react';
import { ContactService } from '../lesson/services';

const Redirect = () => {
	const registrationToCompanyLink = window.location.href;
	const url = new URL(registrationToCompanyLink);
	const fromUMId = url.searchParams.get('fromUMId');
	const redirectTo = url.searchParams.get('to');

	useEffect(() => {
		if (fromUMId && redirectTo) {
			ContactService.setCompanyLinkVisitedDate(fromUMId)
				.then(() => window.open(redirectTo, '_self'));
		}
	}, [fromUMId, redirectTo]);


	return null;
} 

export { Redirect }
