import { Phrases } from '../phrases';

export const vietnameseLocale = {
  [Phrases.orderConsultation]: 'Đặt tư vấn',
  [Phrases.orderingConsultation]: 'Đang đặt tư vấn',
  [Phrases.youAreGoingToOrderConsultation]: 'Bạn sẽ đặt tư vấn',
  [Phrases.phoneNumber]: 'Số điện thoại',
  [Phrases.orderConsultationButton]: 'Đặt tư vấn',
  [Phrases.consultant]: 'Tư vấn viên',
  [Phrases.leaveCommentLabel]: 'Để lại bình luận',
  [Phrases.send]: 'Gửi',
  [Phrases.thankYouForComment]: 'Cảm ơn bạn đã bình luận',
  [Phrases.consultantWillReceiveMessageSoon]: 'Tư vấn viên sẽ nhận tin nhắn sớm',
  [Phrases.previousLesson]: 'Bài học trước',
  [Phrases.nextLesson]: 'Bài học tiếp theo',
  [Phrases.personalConsultant]: 'Tư vấn viên cá nhân',
  [Phrases.consultantWillContactYouForFree]: 'Tư vấn viên sẽ liên hệ với bạn miễn phí',
  [Phrases.contactTelegram]: 'Liên hệ qua Telegram',
  [Phrases.or]: 'Hoặc',
  [Phrases.lessonDescription]: 'Mô tả bài học',
  [Phrases.takeYourBonus]: 'Nhận thưởng của bạn',
  [Phrases.takeBonus]: 'Nhận thưởng',
  [Phrases.rateUsefulnessOfMaterial]: 'Đánh giá tính hữu ích của tài liệu',
  [Phrases.copyright]: 'Bản quyền',
  [Phrases.channelLink]: 'Liên kết kênh',
  [Phrases.registrationToCompanyLink]: 'Liên kết đăng ký vào công ty',
  [Phrases.systemActivationDate]: 'Ngày kích hoạt hệ thống',
  [Phrases.importantLinks]: 'Liên kết quan trọng',
  [Phrases.telegramChannel]: 'Kênh Telegram',
  [Phrases.registrationWith]: 'Đăng ký với',
  [Phrases.systemActivation]: 'Kích hoạt hệ thống',
  [Phrases.timing]: 'Thời gian',
  [Phrases.follow]: 'Theo dõi'
}
