import ReactPlayer from 'react-player';
import { useState } from 'react';
import { PlayerService } from '../../services/Player.service';

import './Player.css';

interface PlayerProps {
    url: string;
    userId: number | string;
    lessonId: number | string;
    className?: string;
}


export const Player = ({ url, userId, lessonId, className }: PlayerProps): JSX.Element => {
    const [ watched, setWatched ] = useState<boolean>(false)
    return (
        <div className="player__wrapper">
            <ReactPlayer
                className={className}
                url={url}
                controls
                width="100%"
                height="100%"
                onProgress={state => {
                    if (state.played > 0.9 && !watched) {
                        PlayerService
                            .setReadingDate(Number(userId), Number(lessonId))
                            .then(() => setWatched(true))
                    }
                }}
            />
        </div>
    );
}
