import { Phrases } from '../phrases';

export const chineseLocale = {
  [Phrases.orderConsultation]: '预约咨询',
  [Phrases.orderingConsultation]: '正在预约咨询',
  [Phrases.youAreGoingToOrderConsultation]: '您将要预约咨询',
  [Phrases.phoneNumber]: '电话号码',
  [Phrases.orderConsultationButton]: '预约咨询',
  [Phrases.consultant]: '顾问',
  [Phrases.leaveCommentLabel]: '留下评论',
  [Phrases.send]: '发送',
  [Phrases.thankYouForComment]: '感谢您的评论',
  [Phrases.consultantWillReceiveMessageSoon]: '顾问将很快收到您的消息',
  [Phrases.previousLesson]: '上一课',
  [Phrases.nextLesson]: '下一课',
  [Phrases.personalConsultant]: '个人顾问',
  [Phrases.consultantWillContactYouForFree]: '顾问将免费与您联系',
  [Phrases.contactTelegram]: '联系 Telegram',
  [Phrases.or]: '或',
  [Phrases.lessonDescription]: '课程描述',
  [Phrases.takeYourBonus]: '领取您的奖金',
  [Phrases.takeBonus]: '领取奖金',
  [Phrases.rateUsefulnessOfMaterial]: '评价材料的有用性',
  [Phrases.copyright]: '版权',
  [Phrases.channelLink]: '频道链接',
  [Phrases.registrationToCompanyLink]: '注册到公司链接',
  [Phrases.systemActivationDate]: '系统激活日期',
  [Phrases.importantLinks]: '重要链接',
  [Phrases.telegramChannel]: 'Telegram 频道',
  [Phrases.registrationWith]: '注册使用',
  [Phrases.systemActivation]: '系统激活',
  [Phrases.timing]: '时间',
  [Phrases.follow]: '关注'
}
