import { Phrases } from '../phrases';

export const filipinoLocale = {
  [Phrases.orderConsultation]: 'Mag-order ng Konsultasyon',
  [Phrases.orderingConsultation]: 'Paghahanda ng Konsultasyon',
  [Phrases.youAreGoingToOrderConsultation]: 'Mag-oorder ka ng konsultasyon',
  [Phrases.phoneNumber]: 'Numero ng Telepono',
  [Phrases.orderConsultationButton]: 'Mag-order ng Konsultasyon',
  [Phrases.consultant]: 'Konsultant',
  [Phrases.leaveCommentLabel]: 'Mag-iwan ng komento',
  [Phrases.send]: 'Ipadala',
  [Phrases.thankYouForComment]: 'Salamat sa iyong komento',
  [Phrases.consultantWillReceiveMessageSoon]: 'Matatanggap ng konsultant ang mensahe sa lalong madaling panahon',
  [Phrases.previousLesson]: 'Nakaraang Leksyon',
  [Phrases.nextLesson]: 'Susunod na Leksyon',
  [Phrases.personalConsultant]: 'Personal na Konsultant',
  [Phrases.consultantWillContactYouForFree]: 'Makikipag-ugnay sa iyo ang konsultant nang libre',
  [Phrases.contactTelegram]: 'Makipag-ugnay sa Telegram',
  [Phrases.or]: 'o',
  [Phrases.lessonDescription]: 'Paglalarawan ng Leksyon',
  [Phrases.takeYourBonus]: 'Kunin ang iyong Bonus',
  [Phrases.takeBonus]: 'Kunin ang Bonus',
  [Phrases.rateUsefulnessOfMaterial]: 'I-rate ang Kapakipakinabang ng Materyal',
  [Phrases.copyright]: 'Karapatan sa Pag-aari',
  [Phrases.channelLink]: 'Link sa Channel',
  [Phrases.registrationToCompanyLink]: 'Link sa Pagrehistro sa Kumpanya',
  [Phrases.systemActivationDate]: 'Petsa ng Aktibasyon ng Systema',
  [Phrases.importantLinks]: 'Mahahalagang Mga Link',
  [Phrases.telegramChannel]: 'Channel sa Telegram',
  [Phrases.registrationWith]: 'Pagrehistro gamit ang',
  [Phrases.systemActivation]: 'Aktibasyon ng Systema',
  [Phrases.timing]: 'Oras',
  [Phrases.follow]: 'Pumili'
}
