import React, { useState } from 'react';
import { Button, Avatar, Image, Form, Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { ConsultationService } from '../../services';
import { Phrases } from '../../../translations/phrases';
import { ConsultantResponse, UserResponse } from '../../interfaces';

import './Consultation.css';

interface ConsultationProps {
    user: UserResponse;
    userMailingId: number;
    consultant: ConsultantResponse;
    visible: boolean;
    onCancel: () => void;
}

export const Consultation = ({
    visible, user, onCancel, consultant, userMailingId
}: ConsultationProps) => {
    const [isOrdered, setOrdered] = useState<boolean>(false);
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const handleClick = (): void => {
        const contact = (user.messengerInfo as any).username ?
        (user.messengerInfo as any).username :
        form.getFieldsValue().contact;

        ConsultationService.orderConsultation(userMailingId).then(() => setOrdered(true));
        ConsultationService.setUserContact(user.id, contact).then();
    }

    return (
        <Modal
            forceRender
            visible={visible}
            footer={null}
            onCancel={onCancel}
        >
            {!isOrdered &&
                <div className="consultant-card">
                    <h1>{t(Phrases.orderingConsultation)}</h1>
                    <p>{t(Phrases.youAreGoingToOrderConsultation)}: </p>
                    <Avatar
                      className="avatar"
                      icon={<Image src={consultant.avatarUrl} />}
                      size={75}
                    />
                    <p>{consultant.firstName + ' ' + consultant.lastName}</p>
                    {!(user.messengerInfo as any).username &&
                        <Form form={form}>
                            <Form.Item name="contact" label={t(Phrases.phoneNumber) + ': '}>
                                <Input id="contact" name="contact" />
                            </Form.Item>
                        </Form>
                    }
                    <p className="order-consultation-button"><Button onClick={handleClick}>{t(Phrases.orderConsultation)}</Button></p>
                </div>
            }
            {isOrdered &&
                <p>{t(Phrases.consultantWillReceiveMessageSoon)}</p>
            }
        </Modal>
    )
}
