import { Col, Row } from 'antd';
import { ReactComponent as GiftImage } from '../../../assets/images/gift-image.svg';
import './GiftBanner.css';
import {useTranslation} from 'react-i18next';
import {Phrases} from '../../../translations/phrases';

interface Props {
	name: string;
	link: string;
}

export const GiftBanner = ({ name, link }: Props): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Row gutter={[0, 20]} className="gift-banner">
			<Row className="gift-content" justify="center">
				<Col xs={24} sm={24} md={4} lg={4}>
					<GiftImage />
				</Col>
				<Col xs={24} sm={24} md={16} lg={16} className="gift-text-wrapper">
					<p className="gift-title">{t(Phrases.takeYourBonus)}</p>
					<p className="gift-name">{name}</p>
				</Col>
				<Col className="gift-btn__wrapper" xs={24} sm={24} md={4} lg={4}>
					<button className="gift-btn">
						<a href={link} className="gift-link" target="_blank" rel="noreferrer">{t(Phrases.takeBonus)}</a>
					</button>
				</Col>
			</Row>
		</Row>
	);
};

