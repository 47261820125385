import { Phrases } from '../phrases';

export const arabicLocale = {
  [Phrases.orderConsultation]: 'اطلب استشارة',
  [Phrases.orderingConsultation]: 'جاري طلب الاستشارة',
  [Phrases.youAreGoingToOrderConsultation]: 'أنت على وشك طلب استشارة',
  [Phrases.phoneNumber]: 'رقم الهاتف',
  [Phrases.orderConsultationButton]: 'اطلب الاستشارة',
  [Phrases.consultant]: 'المستشار',
  [Phrases.leaveCommentLabel]: 'اترك تعليقًا',
  [Phrases.send]: 'إرسال',
  [Phrases.thankYouForComment]: 'شكرًا لتعليقك',
  [Phrases.consultantWillReceiveMessageSoon]: 'سيتلقى المستشار رسالتك قريبًا',
  [Phrases.previousLesson]: 'الدرس السابق',
  [Phrases.nextLesson]: 'الدرس التالي',
  [Phrases.personalConsultant]: 'مستشار شخصي',
  [Phrases.consultantWillContactYouForFree]: 'سيتواصل المستشار معك مجانًا',
  [Phrases.contactTelegram]: 'التواصل عبر تليجرام',
  [Phrases.or]: 'أو',
  [Phrases.lessonDescription]: 'وصف الدرس',
  [Phrases.takeYourBonus]: 'احصل على مكافأتك',
  [Phrases.takeBonus]: 'احصل على المكافأة',
  [Phrases.rateUsefulnessOfMaterial]: 'قيم فائدة المادة',
  [Phrases.copyright]: 'حقوق النشر',
  [Phrases.channelLink]: 'رابط القناة',
  [Phrases.registrationToCompanyLink]: 'رابط التسجيل في الشركة',
  [Phrases.systemActivationDate]: 'تاريخ تفعيل النظام',
  [Phrases.importantLinks]: 'روابط مهمة',
  [Phrases.telegramChannel]: 'قناة التليجرام',
  [Phrases.registrationWith]: 'التسجيل بواسطة',
  [Phrases.systemActivation]: 'تفعيل النظام',
  [Phrases.timing]: 'التوقيت',
  [Phrases.follow]: 'تابع'
}
