import './Copyright.css';
import {useTranslation} from 'react-i18next';
import {Phrases} from '../../../translations/phrases';

export function Copyright(): JSX.Element {
	const { t } = useTranslation();

	return (
		<p className="copyright">&copy;{process.env.REACT_APP_NAME} {new Date().getFullYear()}. {t(Phrases.copyright)}</p>
	);
}
