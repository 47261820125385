import { Phrases } from '../phrases';

export const ukrainianLocale = {
  [Phrases.orderConsultation]: 'Замовити консультацію',
  [Phrases.orderingConsultation]: 'Замова консультації',
  [Phrases.youAreGoingToOrderConsultation]: 'Ви збираєтеся замовити консуьтацію у',
  [Phrases.phoneNumber]: 'Номер телефону',
  [Phrases.orderConsultationButton]: 'Замовити консультацію!',
  [Phrases.consultant]: 'Консультант',
  [Phrases.leaveCommentLabel]: 'Допоможіть нам стати краще, залиште коментар: що сподобалось, що не сподобалось, що на Ваш погляд треба покращити',
  [Phrases.send]: 'Надіслати',
  [Phrases.thankYouForComment]: 'Дякуємо за Ваш коментар',
  [Phrases.consultantWillReceiveMessageSoon]: 'Дякую! Консультант отримає повідомлення та зв\'яжеться з Вами за першої можливості.',
  [Phrases.previousLesson]: 'Попередній',
  [Phrases.nextLesson]: 'Наступний',
  [Phrases.personalConsultant]: 'Ваш особистий консультант',
  [Phrases.consultantWillContactYouForFree]: 'Консультант безкоштовно зв\'яжеться з вами',
  [Phrases.contactTelegram]: 'Зв\'язатися в телеграм',
  [Phrases.or]: 'або',
  [Phrases.lessonDescription]: 'Опис відео',
  [Phrases.takeYourBonus]: 'Заберіть ваш бонус',
  [Phrases.takeBonus]: 'Забрати бонус',
  [Phrases.rateUsefulnessOfMaterial]: 'Оцініть корисність матеріалу',
  [Phrases.copyright]: 'Всі права захищені',
  [Phrases.channelLink]: 'Посилання на канал',
  [Phrases.registrationToCompanyLink]: 'Посилання на реєстрацію в компанії',
  [Phrases.systemActivationDate]: 'Дата активації системи',
  [Phrases.importantLinks]: 'Важливі посилання',
  [Phrases.telegramChannel]: 'Telegram канал',
  [Phrases.registrationWith]: 'Реєстрація в',
  [Phrases.systemActivation]: 'Активація системи',
  [Phrases.timing]: 'Таймінг',
  [Phrases.follow]: 'перейти',
}
