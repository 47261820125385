import { ContactService } from '../../services';
import { Phrases } from '../../../translations/phrases';
import { ConsultantResponse } from '../../interfaces';
import { PaperPlane } from '../../../assets/images/icons';

import './Contact.css';
import {useTranslation} from 'react-i18next';

interface ContactProps {
    userMailingId: number | string;
    consultant: ConsultantResponse;
}

export const Contact = ({ userMailingId, consultant }: ContactProps) => {
    const { telegram } = consultant.contacts;
    const { t } = useTranslation()
    return (
      <div className="contact-button__wrapper">
        <button
                className="contact-button"
                onClick={() => ContactService.setContactedDate(userMailingId).then()}
            >
            <a href={telegram}>
                <PaperPlane className="contact-button_icon"/>
                <span className="contact-button_text">{t(Phrases.contactTelegram)}</span>
            </a>
          </button>
      </div>
    );
}
