import { BASE_API_URL } from '../../utils/constants';

export class ContactService {
    static async setContactedDate (userMailingId: number|string): Promise<void> {
        await fetch(`${BASE_API_URL}/users-mailings/contacted?id=${userMailingId}`, {
            method: 'PATCH'
        });
    }

    static async setSystemActivationLinkVisitedDate (userMailingId: number|string): Promise<void> {
        await fetch(`${BASE_API_URL}/users-mailings/system-activation-link-visited?id=${userMailingId}`, {
            method: 'PATCH'
        });
    }

    static async setCompanyLinkVisitedDate (userMailingId: number|string): Promise<void> {
        await fetch(`${BASE_API_URL}/users-mailings/company-link-visited?id=${userMailingId}`, {
            method: 'PATCH'
        });
    }
}
