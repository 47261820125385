import { useState } from 'react';
import { Button, Card, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { CommentService } from '../../services';
import { Phrases } from '../../../translations/phrases';

const { TextArea } = Input;
interface CommentProps {
    userId: number | string;
    lessonId: number | string;
}


export const Comment = ({ userId, lessonId }: CommentProps) => {
    const [ commentSent, setCommentSent ] = useState<boolean>();
    const [ form ] = Form.useForm();
    const { t } = useTranslation();

    const handleClick = (): void => {
        CommentService
            .create({ userId: Number(userId), lessonId: Number(lessonId), comment: form.getFieldsValue().comment })
            .then(() => setCommentSent(true));
    }

    return (
        <Card>
            { commentSent ?
                <p>{t(Phrases.thankYouForComment)}</p> :
                <Form form={form}>
                    <h4>{t(Phrases.leaveCommentLabel)}</h4>
                    <Form.Item name="comment">
                        <TextArea autoSize={{minRows: 8}}/>
                    </Form.Item>
                    <Button onClick={handleClick}>{t(Phrases.send)}</Button>
                </Form>
            }
        </Card>
    );
}
