import { Phrases } from '../phrases';

export const indonesianLocale = {
  [Phrases.orderConsultation]: 'Pesan Konsultasi',
  [Phrases.orderingConsultation]: 'Memesan Konsultasi',
  [Phrases.youAreGoingToOrderConsultation]: 'Anda akan memesan konsultasi',
  [Phrases.phoneNumber]: 'Nomor Telepon',
  [Phrases.orderConsultationButton]: 'Pesan Konsultasi',
  [Phrases.consultant]: 'Konsultan',
  [Phrases.leaveCommentLabel]: 'Tinggalkan Komentar',
  [Phrases.send]: 'Kirim',
  [Phrases.thankYouForComment]: 'Terima kasih atas komentar Anda',
  [Phrases.consultantWillReceiveMessageSoon]: 'Konsultan akan segera menerima pesan Anda',
  [Phrases.previousLesson]: 'Pelajaran Sebelumnya',
  [Phrases.nextLesson]: 'Pelajaran Selanjutnya',
  [Phrases.personalConsultant]: 'Konsultan Pribadi',
  [Phrases.consultantWillContactYouForFree]: 'Konsultan akan menghubungi Anda secara gratis',
  [Phrases.contactTelegram]: 'Hubungi Telegram',
  [Phrases.or]: 'atau',
  [Phrases.lessonDescription]: 'Deskripsi Pelajaran',
  [Phrases.takeYourBonus]: 'Dapatkan Bonus Anda',
  [Phrases.takeBonus]: 'Dapatkan Bonus',
  [Phrases.rateUsefulnessOfMaterial]: 'Beri Nilai Kepentingan Materi',
  [Phrases.copyright]: 'Hak Cipta',
  [Phrases.channelLink]: 'Tautan Channel',
  [Phrases.registrationToCompanyLink]: 'Tautan Pendaftaran ke Perusahaan',
  [Phrases.systemActivationDate]: 'Tanggal Aktivasi Sistem',
  [Phrases.importantLinks]: 'Tautan Penting',
  [Phrases.telegramChannel]: 'Channel Telegram',
  [Phrases.registrationWith]: 'Pendaftaran dengan',
  [Phrases.systemActivation]: 'Aktivasi Sistem',
  [Phrases.timing]: 'Waktu',
  [Phrases.follow]: 'Ikuti'
}
