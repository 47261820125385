import { Card } from 'antd';

import './LessonDescription.css';
import {useTranslation} from 'react-i18next';
import {Phrases} from '../../../translations/phrases';

interface LessonDescriptionProps {
    html?: string;
}


export function LessonDescription ({ html }: LessonDescriptionProps) {
    const { t } = useTranslation();
    return (
      <div>
        {html && (
            <Card className="lesson-description">
                <h2 className="section-title">{t(Phrases.lessonDescription)}</h2>
                <div className="ql-editor" dangerouslySetInnerHTML={{ __html: html }} />
            </Card>
        )}
        </div>
    )
}
