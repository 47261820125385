import './LessonHeading.css';

interface LessonHeadingProps {
  title: string;
}


export const LessonHeading = ({ title }: LessonHeadingProps): JSX.Element => (
  <h1 className="lessons-title">{title}</h1>
);
