import { Phrases } from '../phrases';

export const spanishLocale = {
  [Phrases.orderConsultation]: 'Solicitar una consulta',
  [Phrases.orderingConsultation]: 'Solicitar una consulta',
  [Phrases.youAreGoingToOrderConsultation]: 'Estás a punto de solicitar una consulta con',
  [Phrases.phoneNumber]: 'Número de teléfono',
  [Phrases.orderConsultationButton]: '¡Solicitar una consulta!',
  [Phrases.consultant]: 'Consultor',
  [Phrases.leaveCommentLabel]: 'Ayúdanos a mejorar, deja tu comentario: qué te gustó, qué no te gustó, qué crees que se debe mejorar',
  [Phrases.send]: 'Enviar',
  [Phrases.thankYouForComment]: 'Gracias por tu comentario',
  [Phrases.consultantWillReceiveMessageSoon]: '¡Gracias! El consultor recibirá la notificación y se pondrá en contacto contigo lo antes posible.',
  [Phrases.previousLesson]: 'Anterior',
  [Phrases.nextLesson]: 'Siguiente',
  [Phrases.personalConsultant]: 'Tu consultor personal',
  [Phrases.consultantWillContactYouForFree]: 'El consultor se pondrá en contacto contigo de forma gratuita',
  [Phrases.contactTelegram]: 'Contactar por Telegram',
  [Phrases.or]: 'o',
  [Phrases.lessonDescription]: 'Descripción de la lección',
  [Phrases.takeYourBonus]: 'Obtén tu bono',
  [Phrases.takeBonus]: 'Obtener bono',
  [Phrases.rateUsefulnessOfMaterial]: 'Evaluar utilidad del material',
  [Phrases.copyright]: 'Derechos de autor',
  [Phrases.channelLink]: 'Enlace del canal',
  [Phrases.registrationToCompanyLink]: 'Enlace de registro a la empresa',
  [Phrases.systemActivationDate]: 'Fecha de activación del sistema',
  [Phrases.importantLinks]: 'Enlaces importantes',
  [Phrases.telegramChannel]: 'Canal de Telegram',
  [Phrases.registrationWith]: 'Registro con',
  [Phrases.systemActivation]: 'Activación del sistema',
  [Phrases.timing]: 'Temporización',
  [Phrases.follow]: 'Seguir'
}
