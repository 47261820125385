import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Lesson } from '../lesson/Lesson';
import { Redirect } from '../redirect/Redirect';
import { i18nSetup } from '../translations/i18n-setup';
import '../utils/helpers/fix-console-warning';
import 'antd/dist/antd.css';
import './App.css';

i18nSetup();

function App () {
  return (
      <BrowserRouter>
          <Switch>
              <Route exact path="/lessons" component={Lesson} />
              <Route exact path="/redirect" component={Redirect} />
          </Switch>
      </BrowserRouter>
  );
}

export default App;
