import { Phrases } from '../phrases';

export const italianLocale = {
  [Phrases.orderConsultation]: 'Ordina una consulenza',
  [Phrases.orderingConsultation]: 'Stai ordinando una consulenza',
  [Phrases.youAreGoingToOrderConsultation]: 'Stai per ordinare una consulenza',
  [Phrases.phoneNumber]: 'Numero di telefono',
  [Phrases.orderConsultationButton]: 'Prenota una consulenza',
  [Phrases.consultant]: 'Consulente',
  [Phrases.leaveCommentLabel]: 'Lascia un commento',
  [Phrases.send]: 'Invia',
  [Phrases.thankYouForComment]: 'Grazie per il commento',
  [Phrases.consultantWillReceiveMessageSoon]: 'Il consulente riceverà presto il messaggio',
  [Phrases.previousLesson]: 'Lezione precedente',
  [Phrases.nextLesson]: 'Lezione successiva',
  [Phrases.personalConsultant]: 'Consulente personale',
  [Phrases.consultantWillContactYouForFree]: 'Il consulente ti contatterà gratuitamente',
  [Phrases.contactTelegram]: 'Contatta Telegram',
  [Phrases.or]: 'O',
  [Phrases.lessonDescription]: 'Descrizione della lezione',
  [Phrases.takeYourBonus]: 'Ricevi il tuo bonus',
  [Phrases.takeBonus]: 'Ricevi il bonus',
  [Phrases.rateUsefulnessOfMaterial]: "Valuta l'utilità del materiale",
  [Phrases.copyright]: "Diritti d'autore",
  [Phrases.channelLink]: 'Link al canale',
  [Phrases.registrationToCompanyLink]: 'Link per la registrazione all\'azienda',
  [Phrases.systemActivationDate]: 'Data di attivazione del sistema',
  [Phrases.importantLinks]: 'Link importanti',
  [Phrases.telegramChannel]: 'Canale Telegram',
  [Phrases.registrationWith]: 'Registrazione con',
  [Phrases.systemActivation]: 'Attivazione del sistema',
  [Phrases.timing]: 'Tempistica',
  [Phrases.follow]: 'Segui'
}
