import { Phrases } from '../phrases';

export const germanLocale = {
  [Phrases.orderConsultation]: 'Beratung bestellen',
  [Phrases.orderingConsultation]: 'Beratung bestellen',
  [Phrases.youAreGoingToOrderConsultation]: 'Sie werden eine Beratung bestellen',
  [Phrases.phoneNumber]: 'Telefonnummer',
  [Phrases.orderConsultationButton]: 'Beratung bestellen',
  [Phrases.consultant]: 'Berater',
  [Phrases.leaveCommentLabel]: 'Kommentar hinterlassen',
  [Phrases.send]: 'Senden',
  [Phrases.thankYouForComment]: 'Vielen Dank für Ihren Kommentar',
  [Phrases.consultantWillReceiveMessageSoon]: 'Der Berater wird bald Ihre Nachricht erhalten',
  [Phrases.previousLesson]: 'Vorherige Lektion',
  [Phrases.nextLesson]: 'Nächste Lektion',
  [Phrases.personalConsultant]: 'Persönlicher Berater',
  [Phrases.consultantWillContactYouForFree]: 'Der Berater wird sich kostenlos mit Ihnen in Verbindung setzen',
  [Phrases.contactTelegram]: 'Telegram kontaktieren',
  [Phrases.or]: 'oder',
  [Phrases.lessonDescription]: 'Lektionsbeschreibung',
  [Phrases.takeYourBonus]: 'Holen Sie sich Ihren Bonus',
  [Phrases.takeBonus]: 'Bonus holen',
  [Phrases.rateUsefulnessOfMaterial]: 'Nützlichkeit des Materials bewerten',
  [Phrases.copyright]: 'Urheberrecht',
  [Phrases.channelLink]: 'Kanallink',
  [Phrases.registrationToCompanyLink]: 'Registrierung bei der Firma',
  [Phrases.systemActivationDate]: 'Systemaktivierungsdatum',
  [Phrases.importantLinks]: 'Wichtige Links',
  [Phrases.telegramChannel]: 'Telegram-Kanal',
  [Phrases.registrationWith]: 'Registrierung mit',
  [Phrases.systemActivation]: 'Systemaktivierung',
  [Phrases.timing]: 'Zeitpunkt',
  [Phrases.follow]: 'Folgen'
}
