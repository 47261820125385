import { Phrases } from '../phrases';

export const turkishLocale = {
  [Phrases.orderConsultation]: 'Danışmanlık Siparişi',
  [Phrases.orderingConsultation]: 'Danışmanlık Siparişi Veriliyor',
  [Phrases.youAreGoingToOrderConsultation]: 'Danışmanlık Siparişi Vermek Üzere İşlem Yapıyorsunuz',
  [Phrases.phoneNumber]: 'Telefon Numarası',
  [Phrases.orderConsultationButton]: 'Danışmanlık Siparişi Ver',
  [Phrases.consultant]: 'Danışman',
  [Phrases.leaveCommentLabel]: 'Yorum Bırak',
  [Phrases.send]: 'Gönder',
  [Phrases.thankYouForComment]: 'Yorumunuz İçin Teşekkür Ederiz',
  [Phrases.consultantWillReceiveMessageSoon]: 'Danışman Yakında Mesajınızı Alacak',
  [Phrases.previousLesson]: 'Önceki Ders',
  [Phrases.nextLesson]: 'Sonraki Ders',
  [Phrases.personalConsultant]: 'Kişisel Danışman',
  [Phrases.consultantWillContactYouForFree]: 'Danışman Sizi Ücretsiz Olarak Arayacak',
  [Phrases.contactTelegram]: 'Telegram ile İletişime Geçin',
  [Phrases.or]: 'veya',
  [Phrases.lessonDescription]: 'Ders Açıklaması',
  [Phrases.takeYourBonus]: 'Bonusunuzu Alın',
  [Phrases.takeBonus]: 'Bonusu Al',
  [Phrases.rateUsefulnessOfMaterial]: 'Materyalin Faydalılığını Oyla',
  [Phrases.copyright]: 'Telif Hakkı',
  [Phrases.channelLink]: 'Kanal Bağlantısı',
  [Phrases.registrationToCompanyLink]: 'Şirkete Kayıt Olma Bağlantısı',
  [Phrases.systemActivationDate]: 'Sistem Aktivasyon Tarihi',
  [Phrases.importantLinks]: 'Önemli Bağlantılar',
  [Phrases.telegramChannel]: 'Telegram Kanalı',
  [Phrases.registrationWith]: 'İle Kayıt Ol',
  [Phrases.systemActivation]: 'Sistem Aktivasyonu',
  [Phrases.timing]: 'Zamanlama',
  [Phrases.follow]: 'Takip Et'
}
