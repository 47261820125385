/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
const consoleError = console.error.bind(console);

// This is a temporary solution
// Can be removed if Formik-Antd will fix their problem
console.error = (errObj: any, ...args: any) => {
	if (process.env.NODE_ENV === 'development' && typeof errObj === 'string' && args.includes('findDOMNode')) {
		return;
	}
	consoleError(errObj, ...args);
};

export { consoleError };
