import { BASE_API_URL } from '../../utils/constants';

export class ConsultationService {
    static async orderConsultation (userMailingId: number | string): Promise<void> {
        await fetch(`${BASE_API_URL}/users-mailings/order-consultation?id=${userMailingId}`, {
            method: 'PUT'
        });
    }

    static async setUserContact (userId: number | string, contact: string): Promise<void> {
        await fetch(`${BASE_API_URL}/users/contact?id=${userId}`, {
            method: 'PUT',
            body: JSON.stringify({ contact }),
            headers: {
              'Content-Type': 'application/json',
            },
        });
    }
}
