import { Phrases } from '../phrases';

export const englishLocale = {
  [Phrases.orderConsultation]: 'Order consultation',
  [Phrases.orderingConsultation]: 'Ordering of the consultation',
  [Phrases.youAreGoingToOrderConsultation]: 'You are going to order the consultation with',
  [Phrases.phoneNumber]: 'Phone number',
  [Phrases.orderConsultationButton]: 'Order consultation!',
  [Phrases.consultant]: 'Consultant',
  [Phrases.leaveCommentLabel]: 'Help us become better, leave your comment: what you liked, what you didn\'t like, what you think should be improved',
  [Phrases.send]: 'Send',
  [Phrases.thankYouForComment]: 'Thank you for your comment',
  [Phrases.consultantWillReceiveMessageSoon]: 'Thank you! The consultant will receive a notification and contact you as soon as possible.',
  [Phrases.previousLesson]: 'Previous',
  [Phrases.nextLesson]: 'Next',
  [Phrases.personalConsultant]: 'Your personal consultant',
  [Phrases.consultantWillContactYouForFree]: 'Consultant will contact you for free',
  [Phrases.contactTelegram]: 'Contact in telegram',
  [Phrases.or]: 'or',
  [Phrases.lessonDescription]: 'Video Description',
  [Phrases.takeYourBonus]: 'Take your bonus',
  [Phrases.takeBonus]: 'Take bonus',
  [Phrases.rateUsefulnessOfMaterial]: 'Rate the usefulness of the material',
  [Phrases.copyright]: 'All rights reserved',
  [Phrases.channelLink]: 'Link to the channel',
  [Phrases.registrationToCompanyLink]: 'Link to the registration to the company',
  [Phrases.systemActivationDate]: 'Date of system activation',
  [Phrases.importantLinks]: 'Important links',
  [Phrases.telegramChannel]: 'Telegram channel',
  [Phrases.registrationWith]: 'Registration with the',
  [Phrases.systemActivation]: 'System activation',
  [Phrases.timing]: 'Timing',
  [Phrases.follow]: 'follow',
}
