import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Consultation} from '../../components';
import { ConsultantResponse, UserResponse } from '../../interfaces';
import { PhoneOutlined } from '../../../assets/images/icons';
import { Phrases } from '../../../translations/phrases';

import './ConsultationButton.css';

interface Props {
    user: UserResponse;
    consultant: ConsultantResponse
    userMailingId: number;
}

export const ConsultationButton = ({ user, consultant, userMailingId }: Props) => {
    const [ visible, setVisible ] = useState<boolean>(false);
    const { t } = useTranslation();
    return (
      <>
        <p className="consultation-button__wrapper">
          <button
            className="consultation-button"
            onClick={ () => setVisible(true) }
          >
            <PhoneOutlined className="consultant-button_icon" />
            <span className="consultant-button_text">{t(Phrases.orderConsultation)}</span>
          </button>
        </p>
        <Consultation
          userMailingId={userMailingId}
          user={user}
          consultant={consultant}
          visible={visible}
          onCancel={() => setVisible(false)}
        />
      </>
    );
}
