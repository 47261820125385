import { useState, useEffect } from 'react';
import { Row, Col, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { ConsultantService, ContactService } from '../../services';
import { ConsultantResponse, ImportantLinksResponse, UserResponse } from '../../interfaces';
import { ConsultationButton, Contact } from '..';
import { DisplayFeedbackControlsEnum } from '../../enums/display-feeback-controls.enum';
import { Phrases } from '../../../translations/phrases';
import { useWindowDimensions } from '../../../utils/hooks';

import './Consultant.css';

export interface ConsultantProps {
	user: UserResponse;
	userMailingId: number;
	consultantId: number | string;
	displayFeedbackControls: DisplayFeedbackControlsEnum | null;
	showSignature?: boolean;
}

export const Consultant = ({
	user,
	userMailingId,
	consultantId,
	displayFeedbackControls,
	showSignature,
}: ConsultantProps) => {
	const [consultant, setConsultant] = useState<ConsultantResponse>();
	const [importantLinks, setImportantLinks] = useState<ImportantLinksResponse>();
	const { t } = useTranslation();
	const { width } = useWindowDimensions();

	const showEveryFeedbackControl =
		displayFeedbackControls === null ||
		displayFeedbackControls === DisplayFeedbackControlsEnum.TELEGRAM_AND_CONSULTATION;
	const showOnlyTelegramControl =
		displayFeedbackControls === DisplayFeedbackControlsEnum.TELEGRAM;
	const showOnlyConsultationControl =
		displayFeedbackControls === DisplayFeedbackControlsEnum.CONSULTATION;

	useEffect(() => {
		ConsultantService.getById(consultantId).then(setConsultant);
		if (showSignature)
			ConsultantService.getImportantLinks(userMailingId).then(setImportantLinks);
	}, [consultantId, userMailingId, showSignature]);

	if (!consultant) return null;
	const { firstName, lastName, avatarUrl, questions } = consultant;

	const handleSystemActivalionLinkClick = () => {
		ContactService.setSystemActivationLinkVisitedDate(userMailingId).then();
	};

	const handleCompanyLinkClick = () => {
		ContactService.setCompanyLinkVisitedDate(userMailingId).then();
	};

	const isNotEmpty = (str: string | null) =>
		str !== null && str !== undefined && str !== '';

	const ImportantLinks = ({ importantLinks }: { importantLinks: ImportantLinksResponse }) => {
		const {
			channelLink,
			registrationToCompanyLink,
			registrationToCompanyLinkLabel,
			systemActivationLink,
			systemActivationLinkLabel,
			showChannelLink,
			showRegistrationToCompanyLink,
			showSystemActivationLink,
		} = importantLinks;

		const showImportantLinks =
			showChannelLink || showRegistrationToCompanyLink || showSystemActivationLink;

		if (!showSignature) return null;
		if (!showImportantLinks) return null;

		return (
			<>
				<p className="section-title important-links-title">
					{t(Phrases.importantLinks)}:
				</p>
				{showChannelLink && isNotEmpty(channelLink) ? (
					<p className="important-link">
						{t(Phrases.telegramChannel)}:{' '}
						<a target="_blank" href={channelLink || '#'} rel="noreferrer">
							{t(Phrases.follow)}
						</a>
					</p>
				) : null}
				{showRegistrationToCompanyLink &&
				isNotEmpty(registrationToCompanyLink) ? (
					<p className="important-link">{`${t(
						Phrases.registrationWith
					)} ${registrationToCompanyLinkLabel}: `}
						<a
							target="_blank"
							href={registrationToCompanyLink || '#'}
								onClick={handleCompanyLinkClick}
							rel="noreferrer"
						>
							{t(Phrases.follow)}
						</a>
					</p>
				) : null}
				{showSystemActivationLink &&
				isNotEmpty(systemActivationLink) ? (
					<p className="important-link">{`${t(
						Phrases.systemActivation
					)} ${systemActivationLinkLabel}: `}
						<a
							target="_blank"
							href={systemActivationLink || '#'}
							onClick={handleSystemActivalionLinkClick}
							rel="noreferrer"
						>
							{t(Phrases.follow)}
						</a>
					</p>
				) : null}
			</>
		);
	};

	return (
		<div className="consultant-wrapper">
			<h2 className="section-title">{t(Phrases.personalConsultant)}</h2>
			<Row justify="space-between">
				<Col className="consultant-image__wrapper" xs={24} sm={24} md={24} lg={8}>
					<img className="consultant-image" alt="consultant" src={avatarUrl} />
				</Col>
				<Col className="consultant_personal-data" xs={24} sm={24} md={24} lg={16}>
					<>
						<p className="consultant-name">{`${firstName} ${lastName}`}</p>
						<p className="consultant-description">{questions.whoAreYou}</p>
						{showEveryFeedbackControl && (
							<>
								<Contact userMailingId={userMailingId} consultant={consultant} />
								{width > 768 ? (
									<Divider orientation="left" className="consultant-divider left">
										{t(Phrases.or)}
									</Divider>
								) : (
									<Divider orientation="center" className="consultant-divider">
										{t(Phrases.or)}
									</Divider>
								)}
								<ConsultationButton
									user={user}
									consultant={consultant}
									userMailingId={userMailingId}
								/>
								<p className="consultant-bottom-text">
									{t(Phrases.consultantWillContactYouForFree)}
								</p>
							</>
						)}
						{showOnlyTelegramControl && (
							<Contact userMailingId={userMailingId} consultant={consultant} />
						)}
						{showOnlyConsultationControl && (
							<>
								<ConsultationButton
									user={user}
									consultant={consultant}
									userMailingId={userMailingId}
								/>
								<p className="consultant-bottom-text">
									{t(Phrases.consultantWillContactYouForFree)}
								</p>
							</>
						)}
						{importantLinks && <ImportantLinks importantLinks={importantLinks} />}
					</>
				</Col>
			</Row>
		</div>
	);
};
