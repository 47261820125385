export enum Phrases {
  orderConsultation = 'Order consultation',
  orderingConsultation = 'Ordering of the consultation',
  youAreGoingToOrderConsultation = 'You are going to order consultation with: ',
  phoneNumber = 'Phone number',
  orderConsultationButton = 'Order consultation!',
  consultant = 'Consultant',
  leaveCommentLabel = 'Help us become better, leave your comment: what you liked, what you didn\'t like, what you think should be improved',
  send = 'Send',
  thankYouForComment = 'Thank you for your comment,',
  consultantWillReceiveMessageSoon = 'The consultant will receive a notification and contact you as soon as possible',
  previousLesson = '← Previous',
  nextLesson = 'Next →',
  personalConsultant = 'Your personal consultant',
  consultantWillContactYouForFree = 'Consultant will contact you for free',
  contactTelegram = 'Contact in telegram',
  or = 'or',
  lessonDescription = 'Lesson Description',
  takeYourBonus = 'Take your bonus',
  takeBonus = 'Take bonus',
  rateUsefulnessOfMaterial = 'Rate the usefulness of the material',
  copyright = 'All rights reserved',
  channelLink = 'Link to the channel',
  registrationToCompanyLink = 'Link to the registration to the company',
  systemActivationDate = 'Date of system activation',
  importantLinks = 'Important links',
  telegramChannel = 'Telegram channel',
  registrationWith = 'Registration with the',
  systemActivation = 'System activation',
  timing = 'Timing',
  follow = 'follow',
}
