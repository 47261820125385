import { Phrases } from '../phrases';

export const portugueseLocale = {
  [Phrases.orderConsultation]: 'Encomendar consulta',
  [Phrases.orderingConsultation]: 'A encomendar consulta',
  [Phrases.youAreGoingToOrderConsultation]: 'Vai encomendar uma consulta',
  [Phrases.phoneNumber]: 'Número de telefone',
  [Phrases.orderConsultationButton]: 'Encomendar consulta',
  [Phrases.consultant]: 'Consultor',
  [Phrases.leaveCommentLabel]: 'Deixe um comentário',
  [Phrases.send]: 'Enviar',
  [Phrases.thankYouForComment]: 'Obrigado pelo seu comentário',
  [Phrases.consultantWillReceiveMessageSoon]: 'O consultor receberá a mensagem em breve',
  [Phrases.previousLesson]: 'Aula anterior',
  [Phrases.nextLesson]: 'Próxima aula',
  [Phrases.personalConsultant]: 'Consultor pessoal',
  [Phrases.consultantWillContactYouForFree]: 'O consultor entrará em contato consigo gratuitamente',
  [Phrases.contactTelegram]: 'Contactar via Telegram',
  [Phrases.or]: 'ou',
  [Phrases.lessonDescription]: 'Descrição da aula',
  [Phrases.takeYourBonus]: 'Receba o seu bónus',
  [Phrases.takeBonus]: 'Receber bónus',
  [Phrases.rateUsefulnessOfMaterial]: 'Avalie a utilidade do material',
  [Phrases.copyright]: 'Direitos de autor',
  [Phrases.channelLink]: 'Link do canal',
  [Phrases.registrationToCompanyLink]: 'Link de registo na empresa',
  [Phrases.systemActivationDate]: 'Data de ativação do sistema',
  [Phrases.importantLinks]: 'Links importantes',
  [Phrases.telegramChannel]: 'Canal Telegram',
  [Phrases.registrationWith]: 'Registo com',
  [Phrases.systemActivation]: 'Ativação do sistema',
  [Phrases.timing]: 'Temporização',
  [Phrases.follow]: 'Seguir'
}
